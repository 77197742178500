import React from 'react';
import { Box } from '@mui/system';
import config from '@configFile';

import UpcomingEvents from '@components/UpcomingEvents';
import FeedSidebar from '@pages/Blog/components/FeedSidebar';
import TopDealsCard from '@components/TopDealsCard';
import Signin from '@components/Signin';
import { sidebarStyle } from '@utils/theme';

interface PageGridProps {
  children?: React.ReactNode;
}

const PageGrid = ({ children = null }: PageGridProps) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          '@media (max-width: 900px)': {
            display: 'block'
          }
        }}
      >
        <Box
          sx={{
            width: '62%',
            '@media (max-width: 1200px)': {
              width: '59%'
            },
            '@media (max-width: 900px)': {
              width: '100%'
            }
          }}
        >
          {children}
        </Box>
        <Box sx={sidebarStyle}>
          <Box key="top-deals" marginTop="16px">
            <TopDealsCard tag={config.AFFILIATE_TAGS.FEED} />
          </Box>
          <UpcomingEvents />
          <Box key="signin" padding="0px 0rem 0px 1rem">
            <Signin showInCard showDontHaveAccount />
          </Box>
          <FeedSidebar />
        </Box>
      </Box>
    </Box>
  );
};

export default PageGrid;
